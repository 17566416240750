<template>
  <div class="index">
    <BjSearch @refresh="onSearch" @init="init">
      <BjInput
        v-model="search.name"
        label="搜索主题"
        v-bind="layout"
        placeholder="请输入主题关键词"
        @pressEnter="onSearch"
      />
      <BjSelect
        v-model="search.group_id"
        v-bind="layout"
        key-field="id"
        label="发布状态"
        inner-search
        placeholder="全部状态"
        value-field="id"
        label-field="name"
        show-all
        :options="statusData"
      />
    </BjSearch>

    <div class="content">
      <div class="title">
        主题列表
        <a-button class="btn" type="primary" @click="onAdd()">
          <i class="ri-add-line" />
          添加主题
        </a-button>
      </div>

      <a-table :loading="loading" :columns="columns" :data-source="data" :pagination="false" row-key="id">
        <template #cover="item">
          <img class="cover" :src="item.cover" />
        </template>
        <template #status="item">
          {{ item.status ? '已发布' : '未发布' }}
        </template>
        <template #action="item">
          <bj-link type="primary" @click="onEdit(item)"> 编辑 </bj-link>
          <a-popconfirm title="确定要删除吗？" @confirm="onDelete(item.id)">
            <bj-link type="danger"> 删除 </bj-link>
          </a-popconfirm>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { resourceMapService } from '@/service'

const service = new resourceMapService()

export default {
  name: 'theme',
  data() {
    return {
      data: [],
      search: {
        name: null,
        status: null,
      },
      loading: false,
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      page: 1,
      statusData: [
        {
          name: '全部',
          id: 0,
        },
        {
          name: '未发布',
          id: 1,
        },
        {
          name: '已发布',
          id: 2,
        },
      ],
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '主题封面',
          scopedSlots: {
            customRender: 'cover',
          },
        },
        { title: '主题名称', dataIndex: 'name' },
        { title: '包含文化点', dataIndex: 'poc_num' },
        { title: '添加时间', dataIndex: 'created_at' },
        {
          title: '发布状态',
          scopedSlots: {
            customRender: 'status',
          },
        },
        {
          title: '管理',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ]
    },
  },
  created() {
    this.getList()
  },
  methods: {
    init() {
      //
    },
    onSearch() {
      this.page = 1
      this.getList()
    },
    async getList() {
      try {
        const { data } = await service.getThemeList({
          page: 1,
          pageSize: 999,
        })
        this.data = data.record
      } catch (e) {}
    },
    onAdd() {
      this.$router.push({
        name: 'resourcesMapthemeAdd',
      })
    },
    async onDelete(id) {
      try {
        await service.themeDelete({
          ids: [id],
        })
        this.$message.success('删除成功')
        this.getList()
      } catch (e) {}
    },
    onEdit(item) {
      this.$router.push({
        name: 'resourcesMapthemeAdd',
        query: {
          id: item.id,
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.content {
  margin-top: 20px;
  padding: 20px;
  background: #fff;
  .title {
    font-size: 16px;
    margin-bottom: 20px;

    .btn {
      float: right;
    }
  }

  .cover {
    width: 80px;
    height: 53px;
    border-radius: 6px;
    object-fit: cover;
  }
}
</style>
